<template>
  <div class="h-100 d-flex align-items-center justify-content-center">      
    <CRow>
      <CCol v-for="(likeType, index) in likesCountPerType" v-bind:key="likeType.like_type" :cols="cols.cols" :xl="cols.xl" :lg="cols.lg" :md="cols.md" :sm="cols.sm" class="pt-0" v-bind:class="{'pb-0' : mode === 'inline' || (mode === 'grid' && index >= 2)}">
        <div class="like d-flex align-items-center justify-content-center">
          <span v-html="getTwemoji(likeType.like_type)" class="mr-2"></span>
          <span class="like_count">{{ likeType.like_count | abbr}}</span>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>

export default {
  name: 'LikesGiven',
  props: ['likesCountPerType', 'mode'],
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  },  
  data() {
    return {
      cols: { cols: 6, xl: 6, lg: 6, md: 6, sm: 6 }
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted: function() {
    if(this.mode === 'inline') {
      this.cols = { cols: 3, xl: 3, lg: 3, md: 3, sm: 3 }
    }
  }
}
</script>
