<template>
  <div v-if="platformPermissionsLoaded && checkPermission('knowledge.insights.knowledge')">
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Knowledge_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol cols="2" xl="2" lg="3" md="4" sm="12">
        <b-datepicker v-model="dateFrom"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateFrom = new Date(); getInsightsData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="2" xl="2" lg="3" md="4" sm="12">
        <b-datepicker v-model="dateTo"
                      icon="calendar-day"
                      icon-pack="fas"
                      :first-day-of-week="1"
                      :show-week-number="true"
                      :max-date="new Date()"
                      :years-range="[-3, 10]"
                      :placeholder="$t('common.click_to_select')"
                      @input="getInsightsData();"
                      class="d-inline-block align-middle">
          <CButton color="primary" @click="dateTo = new Date(); getInsightsData();">
            <i class="fas fa-calendar-day mr-1"/>{{$t('Today')}}
          </CButton>
        </b-datepicker>
      </CCol>
      <CCol cols="2" xl="2" lg="3" md="4" sm="12">
        <multiselect
          class="data_table open_absolute"
          v-model="selectedCategory"
          :options="categories" 
          :multiple="false"
          :close-on-select="true"
          :placeholder="$t('common.Filter_on_category')" 
          :selectLabel="$t('common.Select_category')"
          :deselectLabel="$t('common.Remove_category')"
          track-by="kb_intent_category_id_external" 
          label="label"
          @input="getInsightsData();">
          <span slot="noResult">{{$t('common.no_categories_found')}}</span>
        </multiselect>
      </CCol>
    </CRow>

    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Views')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="single_number" :count="insightsData.metrics[0].views" :countTitle="null" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Reach')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="single_number" :count="insightsData.metrics[0].reach" :countTitle="null" :countIcon="null"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="4" xl="4" lg="4" md="4" sm="4">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.likes_per_type')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LikesGiven v-else :likesCountPerType="insightsData.likes_count_per_type" mode="grid"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="6" xl="6" lg="6" md="6" sm="6">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Top_subcategories')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <TopSubcategories v-else :subcategories="insightsData.subcategories"/>
          </CCardBody>
        </CCard>        
      </CCol> 

      <CCol cols="6" xl="6" lg="6" md="6" sm="6">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Trend')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <LineChart v-else :lineChartSeries="lineChartSeries" xAxisType="datetime" yAxisType="int" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>        
      </CCol>

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.Top_items')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0" v-bind:class="{'p-0' : !loadingInsightsData}">
            <div v-if="loadingInsightsData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <TopItems v-else :platformPermissions="platformPermissions" :items="insightsData.items"/>
          </CCardBody>
        </CCard>        
      </CCol>      
    </CRow> 
  </div>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import Multiselect from 'vue-multiselect';

import Count from '@/components/insights/common/Count.vue';
import LineChart from '@/components/insights/common/LineChart.vue';
import LikesGiven from '@/components/insights/common/LikesGiven.vue';

import TopSubcategories from '@/components/insights/knowledge/TopSubcategories.vue';
import TopItems from '@/components/insights/knowledge/TopItems.vue';

export default {
  name: 'Item',
  components: {
    loadingSpinner,
    noPermission,
    Multiselect,
    Count,
    LineChart,
    LikesGiven,
    TopSubcategories,
    TopItems
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingInsightsData: false,
      insightsApiPath: null,
      insightsData: {
        items: [],
        subcategories: [],
        metrics: [{ 
          views: 0,
          reach: 0
        }]
      },
      showDownloadButtons: false,
      printingInsights: false,
      selectedCategory: null,
      categories: [],
      dateFrom: new Date(),
      dateTo: new Date(),
      companyPrimaryColor: null,
      lineChartSeries: [],
    }
  },
  methods: {
    getInsightsData() {
      // Start the loader
      this.loadingInsightsData = true;
      // Format the dates for the API       
      let dateFromAPI = this.$luxon(this.dateFrom.toISOString(), "yyyy-MM-dd");
      let dateToAPI = this.$luxon(this.dateTo.toISOString(), "yyyy-MM-dd");
      // Check the selectedCategory value to determine the API path   
      (this.selectedCategory) ? 
        this.insightsApiPath = '/v1/knowledge/insights/category/' + this.selectedCategory.kb_intent_category_id_external + '/' + dateFromAPI + '/' + dateToAPI : 
        this.insightsApiPath = '/v1/knowledge/insights/' + dateFromAPI + '/' + dateToAPI;      
      // Get the topics insights data
      axios.get(process.env.VUE_APP_API_URL + this.insightsApiPath)      
      .then(res => {
        // Set the insightsData
        this.insightsData = res.data.data;
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');        
        // Update the lineChartSeries
        this.lineChartSeries = [{ name: "Views", data: this.insightsData.trend }];
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingInsightsData = false;
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;            
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    getCategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/categories/multiselect')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
    
      link.setAttribute("download", "knowledge_insights_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    exportData(outputData, outputFormat) {
      this.$buefy.toast.open({ message: this.$t('common.Export_successful'), type: 'is-success', duration: 2000 });
           
      let exportFileName = 'knowledge-insights';      

      if (!window.navigator.msSaveOrOpenBlob){
        // Blob navigator
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.URL.createObjectURL(new Blob([outputData]));  
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.URL.createObjectURL(new Blob([outputData]));
        }
        // Create a new 'a' element
        const link = document.createElement('a');
        // Set the generated URL as href of the new element
        link.href = url;
        // Set the download attribute based on the output format
        if(outputFormat === 'csv') {          
          link.setAttribute('download', exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          link.setAttribute('download', exportFileName + '.json');
        }
        // Append the link to the body
        document.body.appendChild(link);
        // Click the link
        link.click();
      } else {
        // Blob for Explorer 11
        let url = null;
        // Set the output data and URL based on the output format
        if(outputFormat === 'csv') {
          outputData = outputData.data;
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.csv');
        } else if(outputFormat === 'json') {
          outputData = JSON.stringify(outputData.data.data);
          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.json');
        }
      }
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    // Set the default for dateFrom to today minus 90 days
    this.dateFrom.setDate(this.dateFrom.getDate() -90);
    // Get the insights data
    this.getInsightsData();
    // Get the categories
    this.getCategories();
    this.getPlatformPermissions();
  }
}
</script>