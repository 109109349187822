<template>
  <CRow>
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
               ref="subcategoryTable" 
               :data="itemData" 
               :striped="true" 
               :hoverable="true" 
               :mobile-cards="true" 
               :paginated="isPaginated" 
               :per-page="perPage" 
               :current-page.sync="currentPage" 
               :pagination-simple="isPaginationSimple" 
               :pagination-position="paginationPosition"       
               :default-sort-direction="'desc'"
               :sort-icon="sortIcon"
               :sort-icon-size="sortIconSize"
               default-sort="views">

        <template slot-scope="props">
          <b-table-column field="title" sortable :label="$t('common.Title')" :searchable="searchEnabled" width="40%">
            <span><b>{{props.row.title ? props.row.title : '-'}}</b></span>
            <span class="d-block mt-1 meta">{{props.row.kb_intent_category_name}} > {{props.row.kb_intent_subcategory_name}}</span>
          </b-table-column>
          <b-table-column field="views" sortable :label="$t('common.Total_views')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{props.row.views}}</span>
            </div>
          </b-table-column> 
          <b-table-column field="reach" sortable :label="$t('common.Unique_views')" width="15%">
            <div class="d-flex align-items-center count">
              <span>{{props.row.reach}}</span>
            </div>
          </b-table-column>                    
          <b-table-column field="likes" sortable label="likes" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👍')" class="th_image"></span>
            </template>
            <span>{{props.row.likes}}</span>
          </b-table-column>
          <b-table-column field="dislikes" sortable label="dislikes" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👎')" class="th_image"></span>
            </template>
            <span>{{props.row.dislikes}}</span>
          </b-table-column>
          <b-table-column field="waves" sortable label="waves" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('👏')" class="th_image"></span>
            </template>
            <span>{{props.row.waves}}</span>
          </b-table-column>
          <b-table-column field="hearts" sortable label="hearts" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji('💖')" class="th_image"></span>
            </template>
            <span>{{props.row.hearts}}</span>
          </b-table-column>
          <b-table-column field="actions" label="" :visible="checkPermission('knowledge.insights.item')">
            <div class="d-flex justify-content-lg-end">
              <CButton class="m-0 d-inline-block" color="primary" @click="showItemInsights( props.row.kb_intent_id_external )">
                <i class="far fa-chart-bar"/>
              </CButton>
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('common.No_item_views')}}</span>
          </div>
        </template>     
      </b-table>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'TopItems',
  props: ['platformPermissions', 'items'],
  data() {
    return {
      itemData: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false
    }
  },
  methods: {
    showItemInsights(id) {
      this.$router.push({path: `/insights/knowledge/item/${id.toString()}`});
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted:function() {
		// Set the prop as the table data
		this.itemData = Object.values(this.items);
    // Check if the pagination should be activated
    (this.itemData.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;    
  }
}
</script>
