<template>
  <div class="h-100 d-flex align-items-center justify-content-center" v-bind:class="{'flex-column' : mode === 'with_description'}">
    <div class="count text-center" v-bind:class="{'single_number' : mode === 'single_number', 'with_description mb-2' : mode === 'with_description', 'with_icons mr-1' : mode === 'with_icons'}">
      <span>{{count | abbr}}</span>
    </div>
    <div v-if="countTitle || countIcon" class="d-flex align-items-center justify-content-center">
      <i v-if="countIcon" class="icon top fas mr-1" v-bind:class="countIcon"/>
      <span v-if="countTitle">{{countTitle}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Count',
  props: ['mode', 'count', 'countTitle', 'countIcon'],
  filters: {
    abbr: function(count) {
      let countString = String(count);
      let dotIndex = countString.indexOf(".");

      if(dotIndex > 0) countString = countString.substring(0, dotIndex);

      if(countString.length >= 7) {
        if(count % 1 != 0) {
          return (count/1000000).toFixed(1) + 'M';
        } else {
          return (count/1000000) + 'M';
        }
      } else if(countString.length >= 4) {
        if(count % 1 != 0) {
          return (count/1000).toFixed(1) + 'K';
        } else {
          return (count/1000) + 'K';
        }        
      } else {
        return count;
      }
    }
  }  
}
</script>
