<template>
  <apexchart v-if="this.chartOptions.colors.length > 0"
             width="100%"
             height="250px"
             :options="chartOptions"
             :series="lineChartSeries">
  </apexchart>
</template>

<script>

export default {
  name: 'LineChart',
  props: ['lineChartSeries', 'xAxisType', 'yAxisType', 'companyPrimaryColor'],
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',          
          width: '100%',
          animations: {
            initialAnimation: { enabled: true },
            speed: 200
          }
        },
        stroke: {
          curve: 'smooth',
        },        
        colors: [],
        legend: { show: false },              
        zoom: { autoScaleYaxis: true },
        dataLabels: { enabled: false },
        plotOptions: {
          bar: {
            distributed: true,
            columnWidth: '50%'
          }
        },        
        xaxis: {
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            }
          }
        },
        yaxis: {
          min: 0,
          tickAmount: 1,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            }                 
          }
        },
        tooltip: {
          x: { show: true }
        }        
      }    
    }
  },
  mounted:function() {
    // Set the color of the chart
    this.chartOptions.colors.push(this.companyPrimaryColor);
    // Set the type of the x axis
    this.chartOptions.xaxis.type = this.xAxisType;
    
    if(this.xAxisType === 'datetime') {
      this.chartOptions.tooltip.x.format = 'yyyy-MM-dd';
    }

    if(this.yAxisType === 'decimal') {
      this.chartOptions.yaxis.labels.formatter = function(val) {
        return val.toFixed(1)
      }
    } else {
      this.chartOptions.yaxis.labels.formatter = function(val) {
        return val.toFixed(0)
      }
    }   
  } 
}
</script>
